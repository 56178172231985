import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        onSubmit: _ctx.submitForm,
        "is-submitting": _ctx.isSubmitting
      }, null, 8, ["back-href", "onSubmit", "is-submitting"])
    ]),
    default: _withCtx(() => [
      (_ctx.documentType)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            novalidate: "",
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_FieldValue, {
              label: "Системное название",
              type: "text",
              value: _ctx.documentType.systemName
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Название",
              type: "text",
              value: _ctx.documentType.name
            }, null, 8, ["value"]),
            _createVNode(_component_FieldValue, {
              label: "Следующий номер",
              type: "text",
              value: String(_ctx.documentType.nextNumber)
            }, null, 8, ["value"]),
            _createVNode(_component_FormFieldFileInput, {
              value: _ctx.values.file,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.file) = $event)),
              name: "file",
              error: _ctx.errors.file,
              label: "Шаблон",
              scenario: "template"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.filenameTemplate,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.filenameTemplate) = $event)),
              name: "filenameTemplate",
              error: _ctx.errors.filenameTemplate,
              label: "Имя файла"
            }, null, 8, ["value", "error"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.numberTemplate,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.numberTemplate) = $event)),
              name: "numberTemplate",
              error: _ctx.errors.numberTemplate,
              label: "Номер шаблона"
            }, null, 8, ["value", "error"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}