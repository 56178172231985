import {createWebHistory, type RouteRecordRaw} from "vue-router";

import {createRouter} from '@tager/admin-layout';
import {
    ADMIN_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ROLE_FORM_ROUTE,
    ROLE_LIST_ROUTE,
} from '@tager/admin-administrators';

import Home from '@/views/Home.vue';
import {getLinks} from '@/constants/links';
import {ROUTE_PATHS} from '@/constants/paths';
import DocumentTypeList from '@/views/DocumentTypeList';
import DocumentTypeForm from '@/views/DocumentTypeForm';
import DocumentList from '@/views/DocumentList';

export const HOME_ROUTE: RouteRecordRaw = {
    path: '/',
    component: Home,
    name: 'Home',
    meta: {
        getBreadcrumbs: () => [{url: '/', text: 'Главная'}],
    },
};

export const DOCUMENT_TYPE_LIST_ROUTE: RouteRecordRaw = {
    path: ROUTE_PATHS.DOCUMENT_TYPE_LIST,
    component: DocumentTypeList,
    name: 'Типы документов',
    meta: {
        getBreadcrumbs: () => [
            getLinks().HOME,
            getLinks().DOCUMENT_TYPE_LIST,
        ],
    },
};

export const DOCUMENT_TYPE_FORM_ROUTE: RouteRecordRaw = {
    path: ROUTE_PATHS.DOCUMENT_TYPE_FORM,
    component: DocumentTypeForm,
    name: 'Форма типа документа',
    meta: {
        getBreadcrumbs: (route) => [
            getLinks().HOME,
            getLinks().DOCUMENT_TYPE_LIST,
            {
                url: route.path,
                text: 'Форма типа документа',
            },
        ],
    },
};

export const DOCUMENT_LIST_ROUTE: RouteRecordRaw = {
    path: ROUTE_PATHS.DOCUMENT_LIST,
    component: DocumentList,
    name: 'Документы',
    meta: {
        getBreadcrumbs: () => [getLinks().HOME, getLinks().DOCUMENT_LIST],
    },
};

export const router = createRouter({
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
    routes: [
        HOME_ROUTE,
        DOCUMENT_TYPE_LIST_ROUTE,
        DOCUMENT_TYPE_FORM_ROUTE,
        DOCUMENT_LIST_ROUTE,
        ADMIN_FORM_ROUTE,
        ADMIN_LIST_ROUTE,
        ROLE_FORM_ROUTE,
        ROLE_LIST_ROUTE,
    ],
});

export default router;
