
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from 'vue';
import {
  convertRequestErrorToMap,
  Nullable,
  useResource, useToast,
} from '@tager/admin-services';
import {FullDocumentTypeInterface} from '@/typings/model';
import {getDocumentType, updateDocumentType} from '@/services/requests';
import {
  convertDocumentTypeToFormValues,
  convertFormValuesToDocumentTypeUpdatePayload,
  FormValues,
} from '@/views/DocumentTypeForm/DocumentTypeForm.helpers';
import {FormFooter, TagerFormSubmitEvent, FieldValue, FormField, FormFieldFileInput} from '@tager/admin-ui';
import {getDocumentTypeFormUrl, getDocumentTypeListUrl} from '@/utils/paths';
import {Page} from "@tager/admin-layout";
import {useRoute, useRouter} from "vue-router";

export default defineComponent({
  name: 'DocumentTypeForm',
  components: {Page, FormFooter, FieldValue, FormField, FormFieldFileInput},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const documentTypeId = computed<string>(
        () => route.params.typeId as string
    );

    const [
      fetchDocumentType,
      {data: documentType, loading: isDocumentTypeLoading},
    ] = useResource<Nullable<FullDocumentTypeInterface>>({
      fetchResource: () => getDocumentType(documentTypeId.value),
      initialValue: null,
      resourceName: 'Компания',
    });

    onMounted(() => {
      fetchDocumentType();
    });

    const values = ref<FormValues>(
        convertDocumentTypeToFormValues(documentType.value)
    );

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    watch(documentType, () => {
      values.value = convertDocumentTypeToFormValues(documentType.value);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const updateBody = convertFormValuesToDocumentTypeUpdatePayload(
          values.value
      );
      const requestPromise = updateDocumentType(
          documentTypeId.value,
          updateBody
      );

      requestPromise
          .then((response) => {
            errors.value = {};

            if (event.type === 'create') {
              router.push(
                  getDocumentTypeFormUrl({typeId: response.data.id})
              );
            }
            if (event.type === 'create_exit' || event.type === 'save_exit') {
              router.push(getDocumentTypeListUrl());
            }
            if (event.type === 'create_create-another') {
              values.value = convertDocumentTypeToFormValues(null);
            }

            toast.show({
              variant: 'success',
              title: 'Success',
              body: 'Тип документа успешно обновлен',
            });
          })
          .catch((error) => {
            console.error(error);
            errors.value = convertRequestErrorToMap(error);
            toast.show({
              variant: 'danger',
              title: 'Ошибка',
              body: 'Ошибка при обновлении типа документа',
            });
          })
          .finally(() => {
            isSubmitting.value = false;
          });
    }

    const isContentLoading = computed<boolean>(
        () => isDocumentTypeLoading.value
    );

    const pageTitle = computed<string>(() => {
      return `Редактирование типа документа "${documentType.value?.name}"`;
    });

    return {
      pageTitle,
      isContentLoading,
      values,
      errors,
      documentType,

      submitForm,
      backButtonUrl: getDocumentTypeListUrl(),
      isSubmitting,
    };
  },
});
