
import { defineComponent } from 'vue';
import { getDocumentTypeList } from '@/services/requests';
import { DocumentTypeInterface } from '@/typings/model';
import { getDocumentTypeFormUrl } from '@/utils/paths';
import { ColumnDefinition, useDataTable } from '@tager/admin-ui';
import {Page} from "@tager/admin-layout";
import {DataTable, BaseButton, EditIcon} from "@tager/admin-ui";

const COLUMN_DEFS: Array<ColumnDefinition<DocumentTypeInterface>> = [
  {
    id: 1,
    name: 'ID',
    field: 'id',
    style: { width: '52px', textAlign: 'center', whiteSpace: 'nowrap' },
    headStyle: { width: '52px', textAlign: 'center' },
  },
  {
    id: 2,
    name: 'Название',
    field: 'name',
    type: 'name',
    format: ({ row }) => ({
      adminLink: {
        text: row.name,
        url: getDocumentTypeFormUrl({ typeId: row.id }),
      },
      paramList: [{ name: 'Системное название', value: row.systemName }],
    }),
  },
  {
    id: 4,
    name: 'Файл',
    field: 'file',
    type: 'file',
    style: { width: '150px', textAlign: 'center' },
    headStyle: { width: '150px', textAlign: 'center' },
  },
  {
    id: 5,
    name: 'Имя файла',
    field: 'filenameTemplate',
  },
  {
    id: 6,
    name: 'Номер',
    field: 'numberTemplate',
  },
  {
    id: 7,
    name: 'Действия',
    field: 'actions',
    style: { width: '92px', textAlign: 'center', whiteSpace: 'nowrap' },
    headStyle: { width: '92px', textAlign: 'center' },
  },
];
export default defineComponent({
  name: 'DocumentTypeList',
  components: {Page,DataTable, BaseButton, EditIcon},
  setup() {
    const {
      isLoading: isDocumentTypeListDataLoading,
      rowData: documentTypeListData,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<DocumentTypeInterface>({
      fetchEntityList: (params) =>
        getDocumentTypeList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: 'Список типов документов',
    });

    function isBusy(): boolean {
      return isDocumentTypeListDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: documentTypeListData,
      isRowDataLoading: isDocumentTypeListDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      getDocumentTypeFormUrl,

      isBusy,
    };
  },
});
