
import {computed, defineComponent} from 'vue';

import {ViewListIcon, HomeIcon} from '@tager/admin-ui';
import {MenuItemType, PageLayout} from '@tager/admin-layout';
import {getLinks} from '@/constants/links';

export default defineComponent({
  name: 'App',
  components: {PageLayout},
  setup() {

    const links = computed(() => getLinks());

    const sidebarMenuList: Array<MenuItemType> = [
      {id: 'home', icon: HomeIcon, ...links.value.HOME},
      {id: 'documents', icon: ViewListIcon, ...links.value.DOCUMENT_TYPE_LIST},
      {id: 'documentTypes', icon: ViewListIcon, ...links.value.DOCUMENT_LIST},
      {
        id: 'admins',
        text: 'Administrators',
        icon: ViewListIcon,
        children: [links.value.ADMIN_ADMINS, links.value.ADMIN_ROLES],
      },
    ];

    return {
      sidebarMenuList,
    };
  },
});
